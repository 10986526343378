import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiService } from "../../services/ApiService";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { UserContext } from "../../context/UserProvider";
import { LoginOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics.service";
import AnalyticsCategory from "../../utils/AnalyticsCategory";

export const LoginPage = ({ setLoginRegPage }) => {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    setCurrentUser,

    setUserOrgList,

    setMyRights,

    setMyOrgId,

    setMyRole,
  } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const savedUsername = Cookies.get("username");
    const savedPassword = Cookies.get("password");
    const savedRememberMe =
      Cookies.get("rememberMe") == undefined || Cookies.get("rememberMe") == null ? false : Cookies.get("rememberMe");
    if (savedRememberMe && Boolean(savedRememberMe) && savedRememberMe != "false") {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
    const islogin = sessionStorage.getItem("isLogin") ? true : false;
    const opkey_id = sessionStorage.getItem("opkey");
    if (islogin && opkey_id) {
      // window.location.href = "/dashboard";
      navigate("/dashboard");
    } else {
      sessionStorage.removeItem("isLogin");
    }

    TrackGoogleAnalyticsEvent(
      AnalyticsCategory.InitCategory,
      AnalyticsCategory.InitSuccess,
      window?.location?.pathname || ""
    );

    return () => {};
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!executeRecaptcha) {
      return;
    }
    const recaptchaToken = await executeRecaptcha("login");

    let body = {
      emailId: data.get("email"),
      password: data.get("password"),
      recaptchaToken,
    };

    if (!body.emailId || !body.password) {
      toast.warn("Please fill the required fields!");
      return;
    }
    let tid = toast.loading("Processing...");
    setLoading(true);

    apiService
      .post("authenticate/operator", body)
      .then((res) => res?.data || res)
      .then((response) => {
        TrackGoogleAnalyticsEvent(
          AnalyticsCategory.LoginCategory,
          AnalyticsCategory.LoginActionSuccess,
          window?.location?.pathname || ""
        );

        sessionStorage.setItem("isLogin", true);
        setUserOrgList([...response.data?.organizations]);
        setMyOrgId(response.data?.currentOrg);
        setMyRights([...response.data?.roleRights?.rights]);
        setMyRole(response?.data?.roleRights?.role || "USER");

        sessionStorage.setItem("opkey", response.data?.opkey);

        if (response?.data?.organizations) delete response.data.organizations;
        if (response?.data?.roleRights) delete response.data.roleRights;

        setCurrentUser({ ...response.data });

        toast.success(response.message || "Done");

        if (rememberMe) {
          Cookies.set("username", body.emailId);
          Cookies.set("password", body.password);
          Cookies.set("rememberMe", "true");
        } else {
          Cookies.set("username", "");
          Cookies.set("password", "");
          Cookies.set("rememberMe", "false");
        }

        // window.location.href = "/dashboard";
        navigate("/dashboard");
        // } else {
        //   // toast.error(response.message || "Fail");
        // }
      })
      .catch((err) => {
        TrackGoogleAnalyticsEvent(
          AnalyticsCategory.LoginCategory,
          AnalyticsCategory.LoginActionFail,
          window?.location?.pathname || ""
        );
      })
      .finally(() => {
        setLoading(false);
      });

    sessionStorage.setItem("jwtToken", "success");
  };

  return (
    <>
      <Box sx={{ justifyContent: "center" }}>
        <img src="/Valydate4u.png" style={{ margin: "auto 0.5rem", width: "16rem", height: "100%" }} />
        {/* <span>
          {" "}
          <Typography component="h1" variant="h6">
            Licensing
          </Typography>
          <Typography variant="caption">Welcome to Licensing.</Typography>
        </span> */}
      </Box>
      <Typography component="h1" variant="h5">
        Sign In
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }} autoFocus>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          autoComplete="username"
          name="email"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          InputLabelProps={{ shrink: true }}
        />

        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            fullWidth
            required
            name="password"
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        {/* <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          autoComplete="current-password"
          InputLabelProps={{ shrink: true }}
        /> */}

        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <FormControlLabel
            value={rememberMe}
            control={
              <Checkbox
                checked={rememberMe}
                onChange={(e) => {
                  setRememberMe(e.target.checked);
                }}
                color="primary"
              />
            }
            label="Remember me"
          />
          <Link
            href="#"
            variant="body2"
            onClick={() => {
              window.location.href = "/forgotpassword";
            }}
          >
            Forgot password?
          </Link>
        </Box>

        <Button disabled={loading} type="submit" fullWidth variant="contained" sx={{ mt: 1 }}>
          Sign In
        </Button>
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", mt: 3 }}>
          <Typography variant="p">If you don't have an account please click below to sign up</Typography>
          <Button
            type="button"
            sx={{ width: "auto", mt: 2 }}
            variant="outlined"
            startIcon={<LoginOutlined />}
            onClick={() => {
              setLoginRegPage(true);
            }}
          >
            Go To Sign Up
          </Button>
        </Box>
      </Box>
    </>
  );
};
