import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiService } from "../services/ApiService";
import moment from "moment";

export const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [activeLicense, setActiveLicense] = useState(null);
  const [isLicenseWarn, setIsLicenseWarn] = useState(false);
  const [licenseWarn, setLicenseWarn] = useState("");
  const [licenseRemainDays, setLicenseRemainDays] = useState(null);
  const [myOrgId, setMyOrgId] = useState("");
  const [myRights, setMyRights] = useState(null);
  const [myRole, setMyRole] = useState("");
  const [userOrgList, setUserOrgList] = useState([]);

  useEffect(() => {
    if (!currentUser) {
      getCurrentUser();
    }
    return () => {};
  }, [currentUser]);

  useEffect(() => {
    if (myOrgId && currentUser) {
      getActiveLicense(myOrgId);
    }
    return () => {};
  }, [myOrgId, currentUser]);

  useEffect(() => {
    if (activeLicense) {
      checkAndGetLicenseMeta();
    }
    return () => {};
  }, [activeLicense]);

  const getLicenseFeature = (featureName) => {
    try {
      if (activeLicense) {
        // let _featureName = featureName ? featureName : "all";

        const features = activeLicense?.include?.package?.features || [];
        if (features && features?.length > 0) {
          return features;
        }
        return null;
      }
      // console.log({ featureName });
      return null;
    } catch (error) {
      console.log(error);
    }
  };

  const calculateRemainingDays = (nextDate) => {
    const today = moment().startOf("day"); // Current date at the start of the day
    const target = moment(nextDate, "YYYY-MM-DD").startOf("day"); // Target date at the start of the day

    const diff = target?.diff(today, "days") || 0;
    return diff >= 0 ? diff : 0;
  };

  const checkAndGetLicenseMeta = () => {
    try {
      if (activeLicense) {
        const remaining_days = calculateRemainingDays(activeLicense?.meta?.expiryDate) || 0;
        setLicenseRemainDays(remaining_days);

        if (activeLicense?.meta?.isExpired) {
          setIsLicenseWarn(true);
          setLicenseWarn("Your license is expired, please upgrade your plan.");
        } else if (remaining_days === 0) {
          setIsLicenseWarn(true);
          setLicenseWarn("Please upgrade your license.");
        } else if (remaining_days <= 2) {
          setIsLicenseWarn(true);
          setLicenseWarn(`Your license is going to expire only ${remaining_days} days left, please upgrade it.`);
        }
        return activeLicense?.meta || null;
      }

      return null;
    } catch (error) {
      console.log(error);
    }
  };

  let isLoadingCurrentUser = false;
  const getCurrentUser = async () => {
    if (isLoadingCurrentUser) return;
    isLoadingCurrentUser = true;

    if (!sessionStorage.getItem("opkey")) return;

    if (
      window.location.href.includes("/admin") ||
      window.location.href.includes("/account/activate") ||
      window.location.href.includes("/docs") ||
      window.location.href.includes("/newlicense") ||
      window.location.href.includes("/reNewlicense") ||
      window.location.href.includes("/auth-success-notify") ||
      window.location.href === "https://licensing.axiomprotect.com/" ||
      window.location.href === "http://localhost:5678/" ||
      window.location.href === "http://localhost:3000/"
    )
      return;

    apiService
      .get("operator/currentUser")
      .then((res) => res?.data || res)
      .then((response) => {
        toast.dismiss();
        if (response?.resultCode > 0) {
          setUserOrgList([...response?.data?.organizations]);
          // setMyOrgId(response?.data?.organizations?.length > 0 ? response?.data?.organizations[0]?._id : null);
          
          setMyOrgId(
            response?.data?.currentOrg ||
              (response?.data?.organizations?.length > 0 ? response?.data?.organizations[0]?._id : null)
          );
          setMyRights([...response?.data?.roleRights?.rights]);
          setMyRole(response?.data?.roleRights?.role || "USER");

          if (response?.data?.organizations) delete response.data.organizations;
          if (response?.data?.roleRights) delete response.data.roleRights;

          setCurrentUser({ ...response?.data });
        } else {
          toast.error(response?.message || "Fail");
        }
      })
      .catch((err) => {})
      .finally(() => {
        isLoadingCurrentUser = false;
      });
  };

  let isTokenLoading = false;
  const getAccessRefreshToken = async (orgid, operatorKey) => {
    if (isTokenLoading) return;
    isTokenLoading = true;
    apiService
      .get(`operator/token/update/${orgid}`)
      .then((res) => res?.data || res)
      .then((response) => {
        toast.dismiss();
        if (response?.resultCode > 0) {
          setMyOrgId(orgid);
          sessionStorage.setItem("opkey", operatorKey);
        } else {
          toast.error(response?.message || "Fail");
        }
      })
      .catch((err) => {})
      .finally(() => {
        isTokenLoading = false;
      });
  };

  let fetchingMyLicense = false;
  const getActiveLicense = async (orgid) => {
    if (fetchingMyLicense || currentUser?.licenseOverride) return;
    fetchingMyLicense = true;
    apiService
      .get(`org/license/details/${orgid}`)
      .then((res) => res?.data || res)
      .then((response) => {
        // toast.dismiss();
        if (response?.resultCode > 0 && response?.message === "No license required") {
          setIsLicenseWarn(false);
          setLicenseWarn("");
        } else if (response?.resultCode > 0) {
          setActiveLicense(response?.data);
        } else {
          toast.error(response?.message || "Fail to get license");
        }
      })
      .catch((err) => {})
      .finally(() => {
        fetchingMyLicense = false;
      });
  };

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        myOrgId,
        setMyOrgId,
        userOrgList,
        myRights,
        setMyRights,
        myRole,
        setMyRole,
        setUserOrgList,
        getAccessRefreshToken,
        activeLicense,
        setActiveLicense,
        getLicenseFeature,
        checkAndGetLicenseMeta,
        getActiveLicense,
        isLicenseWarn,
        licenseWarn,
        setIsLicenseWarn,
        licenseRemainDays,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
